module.exports = [{
      plugin: require('../../../node_modules/@wardpeet/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-plugin-rollbar-provider/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"f5708653edc44f8a9732374d04e9ba0e","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production","client":{"javascript":{"code_version":"5f616a4","source_map_enabled":true,"guess_uncaught_frames":true}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
